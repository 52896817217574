import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import InsImage from '../../images/quiz/instantly.png';
import NoSpamImage from '../../images/quiz/no-spam.png';
import HelpImage from '../../images/quiz/help.png';

const Intro = (props) => {
  // console.log(props)
  const canonicalUrl = `/quiz/intro`;
  const seo = {};

  return (
    <Layout>
      <Seo title={`Quiz `} canonical={canonicalUrl} seo={seo} />
      <section className="bg-white my-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <h1 className="mb-md-1 mb-1">Take the Founder IP Quiz</h1>
              <h3 className="my-4" style={{ fontSize: 25 + 'px', fontWeight: 300 }}>Our IP quiz will help you determine which type of IP protection is best suited for your tech company.</h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div class="col-md-4">
              <div className=" text-center">
                <figure>
                  <img src={InsImage} alt="InsImage" width="64px" height="64" />
                  <h5 className="mt-1">Get Results Instantly</h5>
                  <p>Answer a few question's and better understand what type of IP protection is the best fit for you.</p>
                </figure>
              </div>
            </div>
            <div class="col-md-4">
              <div className=" text-center">
                <figure>
                  <img src={NoSpamImage} alt="NoSpamImage" width="64px" height="64" />
                  <h5 className="mt-1">Analyze Results and Raise Your IP Quotient</h5>
                  <p>Taking our Intelligent IP Quiz will instantly reveal which type of IP protection you need. You'll also be directed to the relevant information on our site.</p>
                </figure>
              </div>
            </div>
            <div class="col-md-4">
              <div className=" text-center">
                <figure>
                  <img src={HelpImage} alt="HelpImage" width="64px" height="64" />
                  <h5 className="mt-1">Strategize with an Attorney</h5>
                  <p>To discuss your quiz results and get answers, schedule a free phone call with one of our IP attorneys.</p>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div class="col-md-4 text-center">
              <div class="d-grid gap-2">
                <a href="/quiz/q-1" class="btn btn-warning py-3 fw-bold" type="button">LET'S GET STARTED</a>
                <p className="text-muted">It takes less than 3 minutes!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div class="col-md-12 text-center">
              <p className="fw-bold">Attorney Advertisement</p>
              <p>The results of The Intelligent IP Quiz or information on this website should not be considered legal advice for any individual case or situation. This information is not intended to create, and receipt or viewing does not constitute an attorney-client relationship.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    APPLE_PATENTS: allWpPost(
      filter: {tags: {nodes: {elemMatch: {name: {eq: "Apple"}}}}}
      limit: 100
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        id
        uri
        title
        seo {
            metaDesc
            focuskw
        }
        slug
        date(fromNow: true)
        categories {
          nodes {
            name
          }
        }
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  layout: CONSTRAINED
                  width: 230, 
                  height: 230
                )
              }
            }
          }
        }
      }
    }
  }
`

export default Intro;
